import request from '@/util/request'

export function getNoticeList(params) {
  return request({
    url: '/Notice.asmx/GetNoticeList',
    method: 'GET',
    params,
  })
}

export function getNoticeInfo(params) {
  return request({
    url: '/Notice.asmx/GetNoticeInfo',
    method: 'get',
    params,
  })
}
